.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .login-modal h2 {
    margin-bottom: 20px;
  }
  
  .login-modal form {
    display: flex;
    flex-direction: column;
  }
  
  .login-modal label {
    margin-bottom: 10px;
  }
  
  .login-modal input {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-modal button {
    padding: 10px 20px;
    background-color: #5a9e6f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-modal button:hover {
    background-color: #4a8e5a;
  }
  
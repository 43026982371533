@import url('https://fonts.googleapis.com/css2?family=Cocogoose&display=swap');

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.logo img {
  max-height: 40px;
  padding: 5px 5px;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a,
nav ul li span {
  font-family: 'Cocogoose', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 1.3em;
  cursor: pointer;
}

.login-button {
  font-family: 'Cocogoose', sans-serif;
  background-color: white;
  color: black;
  padding: 5px 40px;
  border-radius: 20px;
}

.login-button:hover {
  background-color: #7DA2CF;
}

/* Estilos para dispositivos móviles */
.mobile-menu-icon {
  display: none;
  font-size: 2em;
  cursor: pointer;
}
/* Ajustes para pantallas móviles */
@media (max-width: 768px) {
  .login-button {
    padding: 10px 40px;
    font-size: 1.2em;
  }
}

/* Ajustes nuevos para pantallas más pequeñas */
@media (max-width: 768px) {
  .logo img {
    max-height: 30px;
  }

  .mobile-menu-icon {
    display: block;
    font-size: 2em;
    cursor: pointer;
    color: white;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60%;
    background-color: rgba(0, 36, 0, 0.9); /* Cambié el fondo a semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 999;
  }

  nav.mobile-menu-open {
    transform: translateX(0);
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 20px 0;
  }

  nav ul li a, nav ul li span {
    font-size: 1.5em;
  }

  .login-button {
    padding: 10px 40px;
    font-size: 1.2em;
  }
}
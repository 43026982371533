@import url('https://fonts.googleapis.com/css2?family=Cocogoose&display=swap');

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita el desplazamiento lateral */
  width: 100vw; /* Asegura que no haya contenido que exceda el ancho de la pantalla */
}

.services-container {
  padding: 20px;
  width: 100vw; /* Asegura que el contenedor ocupe todo el ancho de la ventana */
  max-width: 100%; /* Asegura que el contenedor no exceda el ancho máximo */
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
}

.services-container h2 {
  font-family: 'Cocogoose', sans-serif;
  color: #7DA2CF;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.services-cards {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columnas para mayor control */
  gap: 20px;
  justify-items: center;
}

.service-card {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
}

/* Sección de título */
.service-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.service-title h3 {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 1.2em;
  line-height: 1;
}

.service-card p {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  font-size: 1em;
  text-align: center;
  margin-bottom: 20px;
}

/* Iconos y logo */
.service-icon {
  width: 100%;
  height: auto;
  max-height: 120px;
  object-fit: contain;
}

.company-logo {
  width: 30px;
  height: 20px;
}

/* Posicionamiento de las tarjetas */
.service-card:nth-child(1) {
  grid-column: span 2; /* Ocupa 2 columnas de las 6 disponibles */
}

.service-card:nth-child(2) {
  grid-column: span 2; /* Ocupa 2 columnas de las 6 disponibles */
}

.service-card:nth-child(3) {
  grid-column: span 2; /* Ocupa 2 columnas de las 6 disponibles */
}

.service-card:nth-child(4) {
  grid-column: 2 / span 2; /* Se alinea entre la columna 2 y 3 */
}

.service-card:nth-child(5) {
  grid-column: 4 / span 2; /* Se alinea entre la columna 4 y 5 */
}

@media (max-width: 1024px) {
  .services-cards {
    grid-template-columns: 1fr;
  }

  .service-card {
    grid-column: span 1;
  }
}

/* SECCION DE LA APLICACION DE CELULAR */
.app-screenshots {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.app-screenshots h3 {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  font-size: 2rem;
  margin-bottom: 20px;
}

.app-screenshots p {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  font-size: 1.2em;
  margin-bottom: 40px;
}

.screenshots-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.screenshot {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
}

.screenshot img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.screenshot p {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  margin-top: 10px;
  font-size: 1em;
}

/* SECCION DEL DASHBOARD */

.dashboard-section {
  padding: 20px 10px;
  background-color: #ffffff;
}

.dashboard-section h3 {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: Center;
}

.dashboard-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-column {
  flex: 1;
  padding: 20px;
}

.dashboard-column p {
  font-family: 'Cocogoose', sans-serif;
  color: #002400;
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
}

.dashboard-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .dashboard-row {
    flex-direction: column;
    text-align: center;
  }
  
  .dashboard-column {
    max-width: 100%;
    padding: 0;
  }

  .dashboard-image {
    margin-top: 20px;
  }
}



@media (max-width: 768px) {
  .services-cards {
    grid-template-columns: 1fr; /* Muestra una card por fila */
    gap: 10px; /* Reduce el espacio entre cards */
  }

  .service-card {
    width: 90%; /* Ajusta el ancho de las cards para que quepan bien en móviles */
    margin: 20px auto; /* Asegura que las cards estén centradas */
  }

  .service-title h3 {
    font-size: 1.2em; /* Ajusta el tamaño de los títulos de las cards */
  }

  .service-card p {
    font-size: 1em; /* Ajusta el tamaño del texto en las cards */
  }

  .service-icon {
    max-height: 100px; /* Reduce el tamaño de los íconos en las cards */
  }
}


/* Ajustes adicionales para asegurar que todo se vea correctamente en pantallas pequeñas */
@media (max-width: 480px) {
  .services-container {
    padding: 10px;
  }

  .services-cards {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .service-card {
    width: 100%;
    margin: 0 auto;
  }

  .dashboard-column {
    padding: 10px;
  }

  .dashboard-image {
    width: 100%;
    margin: 0;
    box-shadow: none; /* Opcional: eliminar sombras para simplificar en móviles */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Cocogoose&display=swap');

.about-us-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.about-us-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.about-us-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.about-us-image img {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
  filter: brightness(70%);
}

.about-us-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 20px;
  max-width: 80%;
}

.about-us-content h1 {
  font-family: 'Cocogoose', sans-serif;
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-us-content p {
  font-family: 'Cocogoose', sans-serif;
  font-size: 1.25rem;
  line-height: 1.8;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .about-us-content {
    padding: 20px 10px; /* Ajusta el padding en móviles */
  }

  .about-us-text {
    font-size: 1em; /* Reduce el tamaño del texto para móviles */
    text-align: center; /* Centra el texto */
  }

  .about-us-image {
    max-width: 100%;
    height: auto; /* Asegura que la imagen se ajuste al ancho disponible */
  }
}

@media (max-width: 480px) {
  .about-us-text {
    font-size: 0.9em; /* Ajusta aún más el tamaño del texto en pantallas más pequeñas */
  }

  .about-us-image {
    margin-top: 20px;
    width: 100%;
  }
}

/* src/components/ServiceCard.css */
.service-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-card h3 {
  color: #193863;
  margin-top: 10px;
  font-family: 'Cocogoose', sans-serif;
}

.service-card p {
  color: #555;
  font-family: 'Cocogoose', sans-serif;
  font-size: 1.2em;
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

  
.dashboard-container {
  display: flex;
  height: 100%;
}

.dashboard {
  flex-grow: 1;
  padding: 20px;
  background-color: #f4f4f4;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 20px;
  height: 100%;
}

.chart-container {
  display: flex;
  justify-content: space-between; /* Asegura que las gráficas se distribuyan en la fila */
  gap: 20px; /* Añade un espacio entre las dos gráficas */
  width: 100%; /* Ocupa todo el ancho disponible */
}

.card-container, .card {
  height: auto; /* Permite que las tarjetas ajusten su altura automáticamente */
  width: 100%; /* Ocupa todo el ancho disponible */
  margin-bottom: 10px; /* Espacio entre tarjetas */
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; /* Aumenta el espacio entre las gráficas */
}

.card {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  margin: 10px;
}

.small-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
}
.small-card h2 {
  white-space: normal; /* Permite que el texto se divida en múltiples líneas */
}

h2 {
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem; /* Ajusta el tamaño del texto si es necesario */
  margin: 0;
}

.drawer-paper {
  background-color: #333;
  color: #fff;
}



@import url('https://fonts.googleapis.com/css2?family=Cocogoose&display=swap');

.home-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.hero-section, .info-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-image, .info-image {
  width: 100%;
  height: 100%;
  position: relative;
}

.hero-image img, .info-image img {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
}

.info-image img {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
}

.info-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  padding: 20px;
  text-align: center;
}

.info-content h1 {
  font-family: 'Cocogoose', sans-serif;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.info-content p {
  font-family: 'Cocogoose', sans-serif;
  font-size: 1.2em;
  color: white;
  margin: 0 10px;
}

.image-container {
  display: flex;
  flex-direction: column; /* Cambiar a 'row' para pantallas más grandes */
}

@media (min-width: 768px) {
  .image-container {
    flex-direction: row;
  }
}

img {
  max-width: 100%;
  height: auto;
}


/* Nuevos ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .hero-section, .info-section {
    height: 80vh;
    padding: 20px 10px;
  }

  .hero-image img, .info-image img {
    object-fit: cover;
    max-height: 80vh;
  }

  .info-content h1 {
    font-size: 1.5em; /* Ajustado para que sea más grande y legible en pantallas pequeñas */
  }

  .info-content p {
    font-size: 0.9em;
    padding: 0 15px; /* Ajusta el margen del texto */
  }
}

@media (min-width: 768px) {
  .info-content h1 {
    font-size: 2.5em; /* Para pantallas más grandes */
  }

  .info-content p {
    font-size: 1.2em;
  }
}
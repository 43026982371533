/* Aplica a las líneas del eje (axis lines) */
.MuiLineElement-axis path {
    stroke: white !important; /* Cambia el color de las líneas de los ejes a blanco */
  }
  
  /* Aplica a las etiquetas de los ejes (axis ticks) */
  .MuiLineElement-tick text {
    fill: white !important; /* Cambia el color de las etiquetas del eje a blanco */
  }
  
  /* Aplica a las marcas de los ejes (axis tick lines) */
  .MuiLineElement-tick line {
    stroke: white !important; /* Cambia el color de las marcas del eje a blanco */
  }
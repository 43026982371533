.supported-container {
  margin-top: 40px;
  text-align: center;
}

.supported-container h3 {
  color: #193863;
  margin-bottom: 20px;
  font-size: 2em;
}

.supported-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.supported-logos img {
  height: 120px;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
  .supported-logos img {
    height: auto;
    max-width: 80%;
  }
}

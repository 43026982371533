.contact-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .contact-header h2 {
    color: #193863;
    margin: 0;
  }
  
  .contact-header h1 {
    color: #7DA2CF;
    margin: 0;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    text-align: center;
  }
  
  .contact-info {
    width: 40%;
  }
  
  .contact-details div {
    margin-bottom: 20px;
  }
  
  .contact-details h3 {
    margin: 0;
    color: #193863;
  }
  
  .contact-details p {
    margin: 5px 0;
  }
  
  .contact-form {
    width: 50%;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input, .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
  }
  
  .contact-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #7DA2CF;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #193863;
  }
  
  
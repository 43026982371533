.report-container {
    padding: 20px;
  }
  
  .document {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  button {
    padding: 10px 20px;
    background: #333;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
@import url('https://fonts.googleapis.com/css2?family=Cocogoose&display=swap');

.value-chain-section {
  padding: 10px 10px;
  text-align: center;
  background-color: #f7f7f7;
}

.section-title {
  font-family: 'Cocogoose', sans-serif;
  font-size: 2.5rem;
  color: #193863; /* Color verde para el título */
  margin-bottom: 20px;
}

.image-container {
  display: flex;
  justify-content: center;
}

.value-chain-image {
  max-width: 80%;
  height: auto;
  transition: max-width 0.3s ease; /* Transición suave al cambiar el tamaño */
}

/* Media queries para ajustar el tamaño de la imagen en diferentes tamaños de pantalla */
@media (max-width: 1200px) {
  .value-chain-image {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .value-chain-image {
    max-width: 95%;
  }
}

@media (max-width: 480px) {
  .value-chain-image {
    max-width: 100%;
  }

  .section-title {
    font-size: 2rem;
  }
}
